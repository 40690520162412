import React, { useEffect, useContext } from "react";

import GlobalContext from "../../context/GlobalContext";

const PageWrapper = ({
  children,
  headerDark = false,
  footerDark = false
}: any) => {
  const gContext = useContext(GlobalContext);

  useEffect(() => {
    if (headerDark) {

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      gContext.goHeaderDark();
    } else {

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      gContext.goHeaderLight();
    }

    if (footerDark) {

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      gContext.goFooterDark();
    } else {

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      gContext.goFooterLight();
    }
  }, [gContext, headerDark, footerDark]);

  return <>{children}</>;
};

export default PageWrapper;
